import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ForwardIcon, Icon } from 'boards-web-ui'
import usePaywall from '@hooks/usePaywall'
import useBoards from '@hooks/useBoards'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import styles from './ReverseTrialPlanIndicator.module.css'

const ReverseTrialPlanIndicator = () => {
  const { t } = useTranslation()
  const { openPaywall } = usePaywall()

  const {
    profile: { plan },
  } = useProfileInformation()

  const { boards } = useBoards()
  const sharedBoards = Object.keys(boards)
    .filter((key) => {
      return !boards[key].isOwn && !boards[key].options?.notCountedTowardsLimit
    })
    .map((key) => {
      return boards[key]
    })
  const hasZeroActiveSharedBoard = sharedBoards.every(
    (item) => item.isActive === false,
  )
  const numberBoardsLimit = plan?.limits?.boards
  const shouldShowDescription =
    sharedBoards.length > 0 && numberBoardsLimit > 0 && hasZeroActiveSharedBoard

  const handleUpgrade = () => {
    openPaywall(PaywallSourceEnum.FREE_PLAN_INDICATION)
  }

  return (
    <div
      className={clsx(
        styles.Root,
        shouldShowDescription && styles.withDescription,
      )}
    >
      <div className={styles.FirstRow}>
        <span
          className={clsx(
            styles.Title,
            !shouldShowDescription && styles.TitleWithPadding,
          )}
        >
          {t('free_plan_indication_title')}
        </span>
        <button
          className={clsx(
            styles.ActionButton,
            shouldShowDescription && styles.ActionButtonWithMargin,
          )}
          onClick={handleUpgrade}
        >
          {t('paywall_upgrade')}
          <Icon>
            <ForwardIcon />
          </Icon>
        </button>
      </div>

      {shouldShowDescription && (
        <div className={styles.Description}>
          {t('shared_board_shared_with_me_limit_text', {
            number: numberBoardsLimit,
          })}
        </div>
      )}
    </div>
  )
}

export default ReverseTrialPlanIndicator
