import { FC, memo, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Icon,
  isPageNodeCheckoutForm,
  PagePreviewEmptyStateIcon,
  SpinnerIcon,
  TPageNode,
} from 'boards-web-ui'
import clsx from 'clsx'

import useBoard from '@hooks/useBoard'
import useFolder from '@hooks/useFolder'
import { useToast } from '@hooks/useToast'
import useRouterParams from '@hooks/useRouterParams'
import useCopyShortcuts from '@hooks/useCopyShortcuts'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { hash28 } from '@helpers/Base62'

import { ScreenNames } from '@features/analytics/useNodeEvents'
import usePageEvents from '@features/analytics/usePageEvents'
import { useSharedFolder } from '@hooks/useSharedFolder'
import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'
import { PremiumBanner } from '@features/editorPages/components/PremiumBanner'
import useFormIntegrationStatusState from '@features/forms/hooks/useFormIntegrationStatusState'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'

import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { ProfileFeatures } from '@models/UserProfile'

import { getFoldersWithShortcuts } from '../../../../_firebase'
import { transformNodePage } from '../../../../_firebase/listeners/transformers/NodePageTransformer'
import { transformNodeFolder } from '../../../../_firebase/listeners/transformers/NodeFolderTransformer'
import { selectMissedPageFields } from '../../../../selectors'
import { Folder, Node } from '../../../models'
import PageNodes from '../../nodes/PageNodes'

import PreviewModal from './PreviewModal'
import { LoadFoldersWithShortcutsResponse } from '../../../../_firebase/models/shortcutDataResponse'

import styles from './PreviewPage.module.css'

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folderShared?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const PreviewPage: FC<Props> = ({
  node,
  prev,
  next,
  folderShared,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
}) => {
  const { t } = useTranslation()
  const { boardId } = useRouterParams()
  const [data, setData] = useState<LoadFoldersWithShortcutsResponse>()
  const toast = useToast()

  const {
    sharedFolderState: { shortcutId },
  } = useSharedFolder()

  const [loading, setLoading] = useState(false)
  const [generatedFolder, setGeneratedFolder] = useState<
    Folder | { content: [] }
  >({
    content: [],
  })

  const { folder: folderFromBoardId } = useFolder(boardId, node.id)

  const { isFeatureAvailable: isCheckoutFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.CHECKOUT)
  const missedPageProperties = useSelectorFactory(
    selectMissedPageFields,
    boardId,
    node.id,
    isCheckoutFeatureAvailable,
  )

  const { isFreePlanUser } = useReverseTrialContext()
  const { getPremiumPreviewPageFolder } = usePagePremiumLimitationMode()
  const {
    showPremiumPreviewPageLimitationModeBanner,
    previewPageFolder: folder,
  } = getPremiumPreviewPageFolder(
    isSharedFolderMode && shortcutId
      ? (generatedFolder as Folder)
      : folderFromBoardId,
  )

  const { isPageBoard, isActiveBoard, isOwnBoard } = useBoard()

  const { onGetFormIntegrationStatus, formIntegrationStatusPending } =
    useFormIntegrationStatusState(boardId, node.id || '')

  useEffect(() => {
    if (isSharedFolderMode && shortcutId) {
      setLoading(true)
      const sharerShortcut = `${shortcutId.split('.')[0]}`
      const shortcut = `${sharerShortcut}.${node.shortcut}`
      getFoldersWithShortcuts(shortcut)
        .then((res) => {
          if (res) {
            setData(res)
          }
        })
        .catch(() => {
          toast(t('optimistic_ui_failed'))
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.shortcut])

  useEffect(() => {
    const onDataShortcutChanges = async () => {
      if (data && shortcutId) {
        const folderData = data.folders[0].folder
        const isPage = true

        const content = await Promise.all(
          folderData.content.ids.map(async (id: string) => {
            const generateNodes = folderData.content.properties[id]
            const propertyId = hash28(generateNodes.text || '')

            const properties =
              folderData.urls?.properties &&
              folderData.urls.properties[propertyId]

            return isPage
              ? transformNodePage(
                  boardId,
                  folderData.id,
                  id,
                  generateNodes,
                  properties,
                )
              : transformNodeFolder(id, generateNodes, properties)
          }),
        )

        const newFolder = {
          id: folderData.id,
          icon: folderData.properties.icon,
          title: folderData.properties.title,
          isPage,
          shortcut: shortcutId,
          content,
        } as Folder
        setGeneratedFolder(newFolder)
        setLoading(false)
      }
    }

    onDataShortcutChanges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { viewPageEvent } = usePageEvents()
  const { copyFolderShortcut } = useCopyShortcuts()

  useEffect(() => {
    if (!isSharedFolderMode) {
      viewPageEvent({
        boardId,
        page: folder,
        isPageBoard,
        isActivePage: isActiveBoard,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId, folder?.id])

  const nodesToRender = isSharedFolderMode
    ? generatedFolder?.content
    : folder?.content

  useEffect(() => {
    if (!node.id) {
      return
    }

    nodesToRender?.forEach((nodeItem) => {
      if (
        isPageNodeCheckoutForm(nodeItem as TPageNode) &&
        !formIntegrationStatusPending
      ) {
        onGetFormIntegrationStatus(nodeItem.id)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.id])

  const isEmpty = nodesToRender?.length === 0

  const renderPageContent = useMemo(() => {
    if (loading) {
      return (
        <div className={styles.FolderLoadingSpinner}>
          <Icon size="38px">
            <SpinnerIcon />
          </Icon>
        </div>
      )
    }

    if (isEmpty) {
      return (
        <div className={styles.NoNodesContainer}>
          <PagePreviewEmptyStateIcon />
          <Trans i18nKey={'page_empty_state_title'} />
        </div>
      )
    }

    return <PageNodes nodes={nodesToRender} screen={ScreenNames.PAGE_PREVIEW} />
  }, [isEmpty, loading, nodesToRender])

  const shouldShowPremiumBanners =
    showPremiumPreviewPageLimitationModeBanner || (isOwnBoard && isFreePlanUser)

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyFolderShortcut(folder)}
      prev={prev}
      next={next}
      folder={folderShared}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
      missedIndicator={!!Object.keys(missedPageProperties)?.length}
      shouldShowPremiumBanners={shouldShowPremiumBanners}
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <div
          className={clsx(
            styles.Page,
            isEmpty && styles.EmptyPage,
            shouldShowPremiumBanners && styles.pageWithBanner,
          )}
        >
          {shouldShowPremiumBanners ? (
            <PremiumBanner
              isPagePreview
              isEmpty={isEmpty}
              text={
                isFreePlanUser
                  ? 'page_preview_freemium_banner'
                  : 'page_unsubscribed_banner_text'
              }
            />
          ) : null}

          {renderPageContent}
        </div>
      </div>
    </PreviewModal>
  )
}

export default memo(PreviewPage)
